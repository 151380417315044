<template>
	<div>
		<div class="wrapper">
			<div ref="myModalAround" class="myModal-1-around" @click="hideModals">
			</div>
			<div ref="myModal2" class="myModal-2">
				<v-form ref="form2">
					<v-container class="myModal2container">
						<v-row>
							<v-col cols="6" style="padding-right: 24px;">
								<h2 style="color: black; margin-bottom: 1rem; margin-top: 0.1rem;">Цели монитора:</h2>
								<v-text-field v-model="formLeftRolicsPerMonthTotal" autocomplete="off" autofocus
									label="Выпустить роликов за месяц:" max="100" min="1" placeholder="Введите число" required
									@blur="blurIntegerInput" @keyup="keyUpIntegerInput"></v-text-field>
								<v-text-field v-model="formLeftViewsPerMonthTotal" autocomplete="off"
									label="Просмотров за месяц (млн):" max="999" min="10" placeholder="Введите число" required
									step="50" @blur="blurIntegerInput" @keyup="keyUpIntegerInput"></v-text-field>
								<v-text-field v-model="formLeftNewSubscribersPerMonthTotal" autocomplete="off"
									label="Новых подписчиков за месяц (тыс.)" max="10000" min="10" placeholder="Введите число"
									required step="10" @blur="blurIntegerInput" @keyup="keyUpIntegerInput"></v-text-field>
								<v-text-field v-model="formCurrentMonthViewsDurationTotal" autocomplete="off"
									label="Время просмотра всех роликов в мес. (млн)" max="2000" min="1"
									placeholder="Введите число" required step="10" @blur="blurIntegerInput"
									@keyup="keyUpIntegerInput"></v-text-field>
								<v-text-field v-model="formCurrentMonthPublishedViewsDurationTotal" autocomplete="off"
									label="Время просм. роликов тек. мес. (млн)" max="2000" min="1" placeholder="Введите число"
									required step="10" @blur="blurFloatInput" @keyup="keyUpFloatInput"></v-text-field>
							</v-col>
							<!--                            <v-spacer></v-spacer>-->
							<v-divider vertical></v-divider>
							<v-col cols="6" style="padding-left: 24px;">
								<h2 style="color: black; margin-bottom: 1rem;">Параметры
									монитора:</h2>
								<v-text-field v-model="firstGraphDays" autocomplete="off"
									label="Количество дней на первом графике:" max="28" min="7" placeholder="Введите число"
									required @blur="blurIntegerInput" @keyup="keyUpIntegerInput"></v-text-field>
								<!--                                <v-subheader style="padding: 0;">Смена слайдов через (секунд):</v-subheader>-->
								<!--                                <v-slider v-model="formPeriodOfChangeSlides" min="10" max="20" step="1" thumb-label-->
								<!--                                          ticks-->
								<!--                                >-->
								<!--                                    <template v-slot:append>-->
								<!--                                        <v-text-field-->
								<!--                                                v-model="formPeriodOfChangeSlides"-->
								<!--                                                class="mt-0 pt-0"-->
								<!--                                                hide-details-->
								<!--                                                single-line-->
								<!--                                                type="number"-->
								<!--                                                style="width: 70px"-->
								<!--                                                step="1"-->
								<!--                                        ></v-text-field>-->
								<!--                                    </template>-->
								<!--                                </v-slider>-->
								<v-text-field v-model="formPeriodOfChangeSlides" autocomplete="off"
									label="Смена слайдов через (секунд):" max="120" min="10" placeholder="Введите число" required
									@blur="blurIntegerInput" @keyup="keyUpIntegerInput"></v-text-field>
								<!--                                <v-layout class="mt-5" justify-space-between>-->
								<!--                                    <v-checkbox v-model="showSiteOnTV" dense class=""-->
								<!--                                                label="Отображать на TV" color="black">-->
								<!--                                    </v-checkbox>-->
								<!--                                    <v-spacer></v-spacer>-->
								<v-btn block depressed outlined small style="color: black; margin-top: 24px;"
									@click="logout">смена
									пользователя/тв
								</v-btn>

								<v-btn block depressed outlined small style="color: black; margin-top: 24px;"
									@click="changeTheme">тема
								</v-btn>


								<!--                                </v-layout>-->

							</v-col>
						</v-row>
						<v-divider style="margin-top: 20px; margin-bottom: 20px;"></v-divider>
						<v-layout justify-space-between>
							<v-btn class="" depressed style="color: black" @click="cancelFromModal">Отменить
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn class="" color="primary" depressed style="background-color: #0B1119;"
								@click="saveFromModal">
								Сохранить
							</v-btn>
						</v-layout>
					</v-container>
				</v-form>

			</div>

			<!--       <div style="position: absolute;z-index: 100000; border: 10px solid green; width:1280px; height:720px; background-color:red;">111</div>*/-->
			<div class="left-side">
				<div class="left-side__top">
					<div class="left-side__top-avatar">
						<img alt="" src="../assets/A4VLAD.jpg" @click="changeTheme">
						<div class="avatar-title" @click="makeContentForSlack">
							<div class="avatar-title-text">A4</div>
							<div class="avatar-title-ptichka"></div>

						</div>

						<div class="update-time"><span>↻</span> Обновлено</div>
						<div class="update-time" style="font-size: 78%;margin-bottom: 1rem;">{{ updateTime }}</div>
						<!--                        <div class="update-time">-->
						<!--                            <IOdometer-->
						<!--                                    class="iOdometer"-->
						<!--                                    :value="num"-->
						<!--                            />-->
						<!--                        </div>-->
						<!--                        <div class="update-time">{{ windowScreenWidth + ' : ' + windowScreenHeight }}</div>-->
					</div>

				</div>

				<div class="left-info-panel-item" @click="showModal">
					<div class="left-info-panel-item-title">Дней прошло</div>
					<div class="left-info-panel-item-content">
						<div class="left-info-panel-item-content-icon calendar-icon"></div>
						<div class="left-info-panel-item-content-counter">
							{{ firstDayOfMonth ? '—' : newCurrentMonth.currenDay }}
							<span class="left-info-panel-content-slash">/</span>
							{{ newCurrentMonth.totalDays }}
						</div>
						<div class="left-info-panel-item-content-counter-caption">дней</div>
						<div v-if="!firstDayOfMonth" :style="{ color: '#58D84D' }"
							class="left-info-panel-item-content-counter-percentage">
							{{ newCurrentMonth.percentage }} %
						</div>

					</div>
				</div>

				<div class="left-info-panel-item" @click="showModal">
					<div class="left-info-panel-item-title"> Выпущенных роликов за месяц</div>
					<div class="left-info-panel-item-content">
						<div class="left-info-panel-item-content-icon youtube-icon"></div>
						<div class="left-info-panel-item-content-counter">
							<!-- <span class="left-info-panel-content-slash">(</span> -->
							{{ firstDayOfMonth ? '—' : leftRolicsPerMonth }}
							<span class="left-info-panel-content-slash">+</span>
							<span class="leftRolicsPerMonthShorts">{{ firstDayOfMonth ? '—' : leftRolicsPerMonthShorts
							}}</span>
							<span class="left-info-panel-content-slash">  /</span>
							{{ leftRolicsPerMonthTotal }}
						</div>
						<div class="left-info-panel-item-content-counter-caption">роликов</div>
						<div v-if="!firstDayOfMonth" :style="{ color: leftRolicsPerMonthPercentage.color }"
							class="left-info-panel-item-content-counter-percentage">
							{{ leftRolicsPerMonthPercentage.value }}
						</div>

					</div>
				</div>


				<div class="left-info-panel-item" @click="showModal">
					<div class="left-info-panel-item-title"> Просмотров за месяц</div>
					<div class="left-info-panel-item-content">
						<div class="left-info-panel-item-content-icon eye-icon"></div>
						<div class="left-info-panel-item-content-counter">
							{{ firstDayOfMonth ? '—' : leftViewsPerMonth }}
							<span class="left-info-panel-content-slash">/</span>
							{{ leftViewsPerMonthTotal }} M
						</div>
						<!--                        <div class="left-info-panel-item-content-counter-caption">млн</div>-->
						<div v-if="!firstDayOfMonth" :style="{ color: leftViewsPerMonthPercentage.color }"
							class="left-info-panel-item-content-counter-percentage">
							{{ leftViewsPerMonthPercentage.value }}
						</div>
					</div>
				</div>

				<div class="left-info-panel-item" @click="showModal">
					<div class="left-info-panel-item-title">Новых подписчиков за месяц</div>
					<div class="left-info-panel-item-content">
						<div class="left-info-panel-item-content-icon user-icon"></div>
						<div class="left-info-panel-item-content-counter">
							{{ firstDayOfMonth ? '—' : leftNewSubscribersPerMonth }}
							<span class="left-info-panel-content-slash">/</span>
							<!--                            {{ leftNewSubscribersPerMonthTotal }}-->
							{{ leftNewSubscribersPerMonthTotalSTRING }}
						</div>
						<!--                        <div class="left-info-panel-item-content-counter-caption">тыс</div>-->
						<div v-if="!firstDayOfMonth" :style="{ color: leftNewSubscribersPerMonthPercentage.color }"
							class="left-info-panel-item-content-counter-percentage">
							{{ leftNewSubscribersPerMonthPercentage.value }}
						</div>
					</div>
				</div>

				<!--                <div class="left-info-panel-item">-->
				<!--                    <div class="left-info-panel-item-title">Подписчиков за сутки</div>-->
				<!--                    <div class="left-info-panel-item-content">-->
				<!--                        <div class="left-info-panel-item-content-icon user-icon"></div>-->
				<!--                        <div class="left-info-panel-item-content-counter">-->
				<!--                            {{ leftSubscribersPerDay }}-->
				<!--                        </div>-->
				<!--                        <div class="left-info-panel-item-content-counter-caption">{{-->
				<!--                                leftSubscribersPerDayUnits-->
				<!--                            }}-->
				<!--                        </div>-->

				<!--                    </div>-->
				<!--                </div>-->

				<div class="left-info-panel-item" @click="showModal">
					<div class="left-info-panel-item-title">Общее время просмотра (часы)
						за{{ '\xa0' }}месяц{{ '\xa0' }}
					</div>
					<div class="left-info-panel-item-content">
						<div class="left-info-panel-item-content-icon time-icon"></div>
						<div class="left-info-panel-item-content-counter">

							{{ firstDayOfMonth ? '—' : addMK(currentMonthViewsDuration, 1) }}
							<span class="left-info-panel-content-slash">/</span>
							{{ currentMonthViewsDurationTotalSTRING }}

						</div>

						<div v-if="!firstDayOfMonth" :style="{ color: currentMonthViewsDurationPercentage.color }"
							class="left-info-panel-item-content-counter-percentage">
							{{ currentMonthViewsDurationPercentage.value }}
						</div>
					</div>
					<div class="left-info-panel-item-content">
						<div class="left-info-panel-item-content-icon time-icon"></div>
						<div class="left-info-panel-item-content-counter">

							{{ firstDayOfMonth ? '—' : addMK(currentMonthPublishedViewsDuration, 1) }}
							<span class="left-info-panel-content-slash">/</span>
							{{ currentMonthPublishedViewsDurationTotalSTRING }}

						</div>
						<div v-if="!firstDayOfMonth" :style="{ color: currentMonthPublishedViewsDurationPercentage.color }"
							class="left-info-panel-item-content-counter-percentage">
							{{ currentMonthPublishedViewsDurationPercentage.value }}
						</div>
					</div>
				</div>

				<div class="left-info-panel-item">
					<div class="left-info-panel-item-title">Просмотров всего</div>
					<div class="left-info-panel-item-content">
						<div class="left-info-panel-item-content-icon eye-icon" style="margin-top: 0px;"></div>
						<div class="left-info-panel-item-content-counter">
							<!--                            {{ leftViewsTotal }}-->
							{{ viewsTotalFullNumber }}
						</div>
						<div class="left-info-panel-item-content-counter-caption">
							{{ leftViewsTotalUnits }}
						</div>

					</div>
				</div>

				<!--                    </div>-->
				<!--                </div>-->
			</div>
			<div class="right-side">
				<div class="right-side__slider">
					<div class="slidePrevNextButton slidePrev" @click="changeSlide()">
						<svg fill="none" height="13.33" viewBox="0 0 13 19" width="13.33" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M0.457492 9.46826L8.51999 0.444824H12.6216L4.67624 9.44482V9.58545L12.6216 18.6323H8.30905L0.457492 9.65576V9.46826Z"
								fill="white" fill-opacity="0.5" />
						</svg>

					</div>


					<div class="slide slide-1 active opacity0">
						<div class="slide1-title">
							<div class="number-of-subscribers-text"> Количество подписчиков</div>
							<div class="number-of-subscribers-content">
								<div class="youtube-red"></div>
								<div class="number-of-subscribers">
									<IOdometer :value="numberOfSubscribersNUMBER" class="iOdometer" />
									<!--                                    {{ numberOfSubscribers }}-->
								</div>
							</div>


						</div>
						<svg id="graphSlide1" height="398" viewBox="0 0 3.5 1" width="100%">

						</svg>
					</div>


					<div class="slide slide-2 opacity0">
						<div class="slide2-title">
							<div class="number-of-subscribers-text">Просмотры за последние 48 часов</div>
							<div class="number-of-subscribers-content">
								<svg fill="none" height="68" viewBox="0 0 110 68" width="110"
									xmlns="http://www.w3.org/2000/svg">
									<path clip-rule="evenodd"
										d="M109.143 36.4474C110.243 35.0778 110.288 33.1469 109.255 31.7325C108.312 30.4527 86.1521 0.726326 54.2478 0.726326C35.4331 0.726326 17.4715 11.0991 0.857067 31.5753C-0.24308 32.9449 -0.287983 34.8758 0.744807 36.2902C1.66534 37.5475 23.8254 67.2739 55.7297 67.2739C74.5444 67.2739 92.506 56.9011 109.143 36.4474ZM8.80506 34.0226C23.309 17.0489 38.5764 8.42735 54.2478 8.42735C77.4407 8.42735 95.4921 27.2645 101.172 33.9777C86.6685 50.9514 71.4011 59.5729 55.7297 59.5729C32.5368 59.5729 14.4854 40.7357 8.80506 34.0226ZM35.4325 34C35.4325 44.777 44.2112 53.5557 54.9882 53.5557C65.7651 53.5557 74.5438 44.777 74.5438 34C74.5438 23.2231 65.7651 14.4444 54.9882 14.4444C44.2112 14.4444 35.4325 23.2231 35.4325 34ZM43.1335 34C43.1335 27.4665 48.4546 22.1454 54.9882 22.1454C61.5217 22.1454 66.8428 27.4665 66.8428 34C66.8428 40.5335 61.5217 45.8546 54.9882 45.8546C48.4546 45.8546 43.1335 40.5335 43.1335 34Z"
										fill="white" fill-rule="evenodd" />
								</svg>

								<div class="number-of-subscribers">
									<!--                                    {{ numberOfViewsPer48Hours }}-->
									<IOdometer :value="numberOfViewsPer48HoursNUMBER" class="iOdometer" />
								</div>
							</div>
						</div>

						<svg id="graphSlide2" height="398" viewBox="0 0 3.5 1" width="100%">
						</svg>
					</div>

					<div class="slide slide-3 opacity0">
						<div class="slide3-title">
							<div class="number-of-subscribers-text">Лучшие недавние видео по количеству просмотров</div>
							<div v-if="!noTooltip" class="number-of-subscribers-text"
								style="font-size: 1rem; font-weight: 400;">
								{{ dataPeriodLastEffect }}
							</div>
						</div>
						<div v-if="noTooltip" style="margin-top: 20px; margin-left: 12px; color: gray;">даные недоступны
							из-за публикации ролика менее часа назад</div>
						<div v-if="!noTooltip" class="bestLastVideos">
							<div v-for="(video, idx) in bestLastVideos" :key="idx" :class="{ 'videoNew': video.highlighted }"
								class="bestLastVideo">
								<div style="text-align: center;">
									{{ idx + 1 }}
								</div>
								<div>
									<img :src="video.videoURL" alt="video" width="100%">
									<!--                                <img src="https://img.youtube.com/vi/P83LmJtcXLA/mqdefault.jpg" class="videoPreview"-->
									<!--                                     alt="video">-->
								</div>
								<div style="display: flex;">
									<span v-if="video.highlighted" class="newVideoSpan">(НОВОЕ)</span><span class="title">{{
										video.title }}</span>
								</div>
								<div>
									<strong>
										{{ video.views }}
									</strong>
								</div>
							</div>
						</div>
					</div>

					<div class="slide slide-4 opacity0">
						<div class="slide4-title">
							<div class="number-of-subscribers-text" style="margin-top: 3rem; margin-bottom: 2rem;">
								Эффективность недавно <br>загруженного видео
							</div>

						</div>
						<div v-if="noTooltip" style="margin-top: 20px; margin-left: 12px; color: gray;">даные недоступны
							из-за публикации ролика менее часа назад</div>
						<div v-if="!noTooltip" class="slide4-content">
							<div>

								<!--                                <div class="gradientBottom">-->
								<img :src="firstBottomRolic.URL.replace('mqdef', 'hqdef')" alt="первый ролик"
									style="border-radius: 6px; object-fit: cover; height:180px;" width="100%">
								<!--                                </div>-->
								<div class="firstBottomRolic-title" style="font-size: 1.75rem; line-height: 2.5rem; margin-top: 1rem; position: relative; z-index: 2;
overflow-y: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;">
									{{ firstBottomRolic.title }}
								</div>
								<p class="firstBottomRolic-pubDate" style="color: #888888; margin-top: 1rem;">{{
									firstBottomRolic.pubDate }}</p>
							</div>
							<div>
								<!--                                <div v-for="(t, idx) in slide4arr" :key="idx">-->
								<!--                                    <p class="slide4text">-->
								<!--                                        {{ t }}-->

								<!--                                    </p>-->
								<!--                                </div>-->
								<p class="slide4text" style="margin-top: -4.8rem;">{{ slide4arr[0] }}</p>
								<div class="slide4text-flex">
									<p class="slide4text">{{ slide4arr[1] }}</p>
									<p class="slide4text" style="text-align: right;">{{ slide4arr[2] }}</p>
									<div class="icon" v-html="slide4arrIcons[0]"></div>
								</div>
								<p class="slide4text slide4text-desc"></p>
								<div class="slide4text-flex">
									<p class="slide4text">{{ slide4arr[3] }}</p>
									<p class="slide4text" style="text-align: right;">{{
										slide4arr[4]
									}}</p>
									<div class="icon" v-html="slide4arrIcons[1]"></div>
								</div>
								<p class="slide4text slide4text-desc">{{ slide4arr[5] }}</p>
								<div class="slide4text-flex">
									<p class="slide4text">{{ slide4arr[6] }}</p>
									<p class="slide4text" style="text-align: right;">{{
										slide4arr[7]
									}}</p>
									<div class="icon" v-html="slide4arrIcons[2]"></div>
								</div>
								<p class="slide4text slide4text-desc">{{ slide4arr[8] }}</p>
								<div class="slide4text-flex">
									<p class="slide4text">{{ slide4arr[9] }}</p>
									<p class="slide4text" style="text-align: right;">{{
										slide4arr[10]
									}}</p>
									<div class="icon" v-html="slide4arrIcons[3]"></div>
								</div>
								<p class="slide4text slide4text-desc">{{ slide4arr[11] }}</p>

								<div class="slide4text-flex">
									<p class="slide4text">{{ slide4arr[12] }}</p>
									<p class="slide4text" style="text-align: right;">{{
										slide4arr[13]
									}}</p>
									<div class="icon" v-html="slide4arrIcons[4]"></div> <!-- !!!!!!!!!!!!!!!-->
								</div>
								<p class="slide4text slide4text-desc">{{ slide4arr[14] }}</p>

							</div>
						</div>

					</div>

					<div class="slidePrevNextButton slideNext" @click="changeSlide('next')">
						<svg fill="none" height="13.33" viewBox="0 0 13 19" width="13.33" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M12.5044 9.46826L4.44192 0.444824H0.34036L8.28567 9.44482V9.58545L0.34036 18.6323H4.65286L12.5044 9.65576V9.46826Z"
								fill="white" fill-opacity="0.5" />
						</svg>
					</div>
				</div>

				<div class="right-side__bottom">
					<div class="bottom-rolics-title" style="font-size: 0.8rem; line-height: 2.6rem; color: #888;">
						Последнее видео
					</div>
					<div class="bottom-rolics">
						<div class="first-bottom">
							<div>
								<div class="imageWithDuration">
									<img :src="firstBottomRolic.URL" alt="первый ролик" style="border-radius: 6px" width="100%">
									<div class="rolicDuration">{{ firstBottomRolic.duration }}</div>
								</div>
								<div class="firstBottomRolic-title">
									{{ firstBottomRolic.title }}
								</div>
								<span class="firstBottomRolic-pubDate">{{ firstBottomRolic.pubDate }}</span>
								<!--                            <svg class="bottom-svg-icon" width="1.6rem" height="29" viewBox="0 0 32 29" fill="none"-->
								<!--                                 xmlns="http://www.w3.org/2000/svg">-->
								<!--                                <path-->
								<!--                                        d="M30.7249 9.82747C30.1474 9.08466 29.2864 8.65859 28.3628 8.65859H22.9332L24.2466 4.00236C24.8137 1.99193 23.35 0 21.3335 0C20.4477 0 19.6088 0.397352 19.0318 1.09027L12.9007 8.45284C12.7918 8.58359 12.6369 8.65859 12.4758 8.65859H9.65386V8.03568C9.65386 7.34762 9.10487 6.78987 8.42762 6.78987H1.22625C0.54899 6.78987 0 7.34762 0 8.03568V27.4079C0 28.096 0.54899 28.6537 1.22625 28.6537H8.42768C9.10493 28.6537 9.65392 28.096 9.65392 27.4079V26.785H25.5785C26.9817 26.785 28.1919 25.8027 28.5214 24.3963L31.3058 12.5139C31.5277 11.5669 31.316 10.5877 30.7249 9.82747ZM7.20143 26.1621H2.45243V9.2815H7.20143C7.20143 9.71442 7.20143 25.7543 7.20143 26.1621ZM28.92 11.9369L26.1356 23.8193C26.0702 24.0985 25.8411 24.2934 25.5784 24.2934H9.65386V11.1502H12.4758C13.3598 11.1502 14.197 10.7537 14.7729 10.0622L20.9039 2.69962C21.3185 2.20166 22.0723 2.66623 21.889 3.31592L20.4667 8.35872C20.0714 9.75983 21.0953 11.1502 22.5096 11.1502H28.3628C28.5936 11.1502 28.736 11.2891 28.8004 11.3719C28.9235 11.5302 28.9671 11.7361 28.92 11.9369Z"-->
								<!--                                        fill="#58D84D"/>-->
								<!--                            </svg>-->
								<!--                            <span class="firstBottomRolic-pubDate">{{ firstBottomRolic.likes }}</span>-->
							</div>
							<div>
								<div class="firstBottomRolic-number">{{ addMK(firstBottomRolic.views, 1) }}</div>
								<!--                                <div class="firstBottomRolic-number">{{ fr1 }}</div>-->
								<div class="firstBottomRolic-caption">просмотров</div>
								<div class="firstBottomRolic-number">{{ addMK(firstBottomRolic.likes, 1) }}</div>
								<div class="firstBottomRolic-caption">лайков</div>
								<div class="firstBottomRolic-number">{{ addMK(firstBottomRolic.comments, 1) }}</div>
								<div class="firstBottomRolic-caption">комментариев</div>
								<div class="firstBottomRolic-number">{{ firstBottomRolic.averageViewDuration }}</div>
								<div class="firstBottomRolic-caption">удержание</div>
							</div>
						</div>
						<div class="three-bottom-rolics">
							<div v-for="(video, idx) in threeBottomRolics" :key="idx" class="bottom-right-rolic">

								<div class="imageWithDuration">

									<img :src="video.URL" alt="первый ролик" style="border-radius: 6px" width="100%">
									<div class="rolicDuration">{{ video.duration }}</div>
								</div>

								<div class="threeBottomRolics-title"> {{ video.title }}</div>
								<div class="threeBottomRolics-number" style="margin-top: 0.25rem;">{{ video.views }}
									просмотров
								</div>
								<div class="threeBottomRolics-number" style="margin-top: 0.25rem;">{{
									video.pubDate
								}}
								</div>
								<div class="dataWithIconsContainer">
									<div>
										<svg class="bottom-svg-icon" fill="none" height="13.997" viewBox="0 0 23 21"
											width="14.663" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M22.5113 7.08723C22.0882 6.55154 21.4574 6.24428 20.7807 6.24428H16.8026L17.7648 2.88637C18.1803 1.43651 17.108 0 15.6305 0C14.9815 0 14.3668 0.286557 13.9441 0.786268L9.45197 6.0959C9.37219 6.19019 9.25871 6.24428 9.1407 6.24428H7.07313V5.79506C7.07313 5.29885 6.6709 4.89662 6.17469 4.89662H0.898438C0.402231 4.89662 0 5.29885 0 5.79506V19.7656C0 20.2618 0.402231 20.6641 0.898438 20.6641H6.17474C6.67094 20.6641 7.07317 20.2618 7.07317 19.7656V19.3164H18.7407C19.7688 19.3164 20.6554 18.608 20.8969 17.5938L22.9369 9.02458C23.0995 8.34163 22.9444 7.63551 22.5113 7.08723ZM5.2763 18.8672H1.79683V6.69349H5.2763C5.2763 7.0057 5.2763 18.5731 5.2763 18.8672ZM21.1889 8.60847L19.1489 17.1777C19.101 17.379 18.9331 17.5196 18.7406 17.5196H7.07313V8.04115H9.1407C9.78839 8.04115 10.4018 7.75518 10.8237 7.2565L15.3158 1.94687C15.6195 1.58776 16.1718 1.92279 16.0375 2.39133L14.9954 6.02802C14.7058 7.03845 15.456 8.04115 16.4922 8.04115H20.7807C20.9498 8.04115 21.0541 8.14128 21.1013 8.20103C21.1914 8.31517 21.2234 8.46369 21.1889 8.60847Z"
												fill="#58D84D" />
										</svg>
										<span class="threeBottomRolics-number">{{ addMK(video.likes, 1) }}</span>
									</div>
									<div>
										<svg class="bottom-svg-icon" fill="none" height="13.33" viewBox="0 0 22 20" width="14.663"
											xmlns="http://www.w3.org/2000/svg">
											<mask id="path-1-inside-1" fill="white">
												<path
													d="M0 3C0 1.34315 1.34315 0 3 0L19 0C20.6569 0 22 1.34315 22 3V12C22 13.6569 20.6569 15 19 15H13H11L6.14594 19.1073C5.49603 19.6572 4.5 19.1953 4.5 18.3439V15H3C1.34315 15 0 13.6569 0 12V3Z" />
											</mask>
											<path
												d="M4.5 15H6.5V13H4.5V15ZM11 15V13H10.2674L9.70812 13.4732L11 15ZM3 2H19V-2H3V2ZM20 3V12H24V3H20ZM2 12V3H-2V12H2ZM19 13H13V17H19V13ZM6.5 18.3439V15H2.5V18.3439H6.5ZM4.5 13H3V17H4.5V13ZM13 13H11V17H13V13ZM9.70812 13.4732L4.85406 17.5805L7.43783 20.6341L12.2919 16.5268L9.70812 13.4732ZM-2 12C-2 14.7614 0.238576 17 3 17V13C2.44772 13 2 12.5523 2 12H-2ZM20 12C20 12.5523 19.5523 13 19 13V17C21.7614 17 24 14.7614 24 12H20ZM19 2C19.5523 2 20 2.44772 20 3H24C24 0.238577 21.7614 -2 19 -2V2ZM2.5 18.3439C2.5 20.898 5.48808 22.2838 7.43783 20.6341L4.85406 17.5805C5.50397 17.0306 6.5 17.4925 6.5 18.3439H2.5ZM3 -2C0.238577 -2 -2 0.238576 -2 3H2C2 2.44772 2.44772 2 3 2V-2Z"
												fill="#55BBDB" mask="url(#path-1-inside-1)" />
										</svg>
										<span class="threeBottomRolics-number">{{ addMK(video.comments, 1) }}</span>
									</div>
									<div>
										<svg class="bottom-svg-icon" fill="none" height="15.33" viewBox="0 0 23 23" width="15.33"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M11.3832 11.4086C9.76824 11.4086 8.45937 10.0997 8.45937 8.48485C8.45937 6.86998 9.76829 5.56106 11.3832 5.56106C12.998 5.56106 14.3069 6.86998 14.3069 8.48485C14.3069 10.0994 12.998 11.4086 11.3832 11.4086ZM6.83506 15.6319V16.9314H15.9316V15.6319C15.9316 13.8377 14.4773 12.3833 12.6829 12.3833H10.084C8.2894 12.3831 6.83506 13.8377 6.83506 15.6319ZM21.4524 6.04674C19.4811 2.35147 15.6071 0 11.3832 0C6.91266 0 2.83177 2.63193 0.98614 6.7054C0.794691 7.12757 0.98209 7.62501 1.40465 7.81681C1.82652 8.00727 2.32431 7.82115 2.51605 7.39829C4.08997 3.92436 7.57028 1.67967 11.3832 1.67967C14.9495 1.67967 18.2242 3.6442 19.9211 6.74097L18.424 7.41909L22.507 10.3453L23 5.3461L21.4524 6.04674ZM21.2203 15.3717C20.8055 15.1638 20.301 15.3334 20.0937 15.7478C18.4341 19.0723 15.0962 21.138 11.3828 21.138C7.9152 21.138 4.77644 19.336 3.02456 16.3904L4.49535 15.6565L0.305992 12.8848L0 17.8991L1.51914 17.1413C3.56028 20.6596 7.27437 22.8173 11.3828 22.8173C15.7367 22.8173 19.6504 20.3957 21.5961 16.4979C21.8037 16.0826 21.6351 15.5786 21.2203 15.3717Z"
												fill="#FFDF6E" />
										</svg>
										<span class="threeBottomRolics-number">{{ video.averageViewDuration }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import fb from "firebase";
import Cookies from 'js-cookie';
import '@/assets/Fonts/fontsStylesheet.css';

import IOdometer from 'vue-odometer';
import 'odometer/themes/odometer-theme-default.css';

export default {
	name: "Monitor",
	components: {
		IOdometer
	},
	data: () => ({

		formCurrentMonthPublishedViewsDurationTotal: 0,
		currentMonthPublishedViewsDurationTotal: 10,
		currentMonthPublishedViewsDuration: 0,

		formCurrentMonthViewsDurationTotal: 0,
		currentMonthViewsDurationTotal: 10,
		currentMonthViewsDuration: 0,
		timeStamp: +new Date(),
		subscribers28increment: [],
		subscribers28incrementBadData: false,
		subscribers28incrementObject: {},
		firstDayOfMonth: false,
		scaleFactor: 1,
		viewsTotalFullNumber: '-',
		pubDates: [],
		pubTimes: [],
		odometerSetInterval: null,
		num: 1000,
		showSiteOnTV: 1,
		subscribersArr: [],

		canSetGoals: false,
		dataPeriodLastEffect: '',
		slide4arr: [],
		slide4arrIcons: [],

		formPeriodOfChangeSlides: 10,
		firstGraphDays: 28,
		formLeftNewSubscribersPerMonthTotal: 5,
		formLeftViewsPerMonthTotal: 2,
		formLeftRolicsPerMonthTotal: 12,

		periodOfChangeSlidesRules: [
			// (v) => !!v || 'E-mail обязателеное поле ввода',
			// (v) => !isNaN(parseInt(v)) || 'Введите целое число',
			(v) => {
				return (parseInt(v) >= 10 && parseInt(v) <= 120) || 'Целое число от 10 до 120'
			}
		],

		firstGraphDaysRules: [
			// (v) => !!v || 'E-mail обязателеное поле ввода',
			// (v) => !isNaN(parseInt(v)) || 'Введите целое число',
			(v) => {
				return (parseInt(v) >= 7 && parseInt(v) <= 28) || 'Целое число от 7 до 28'
			}
		],

		firstBottomRolic: {
			URL: 'https://img.youtube.com/vi/_Yk5l1eR4mw/mqdefault.jpg',
			views: '8.6 млн',
			comments: ' 952 тыс',
			averageViewPercentage: '68 %',
			title: 'Как Помочь ДРУГУ Сбежать Из ТЮРЬМЫ ! - Челлендж',
			pubDate: '23 янв. 2021 г.',
			likes: '676 тыс',
		},

		windowScreenWidth: window.screen.width,
		windowScreenHeight: window.screen.height,

		threeBottomRolics: [
			{
				URL: 'https://img.youtube.com/vi/_Yk5l1eR4mw/mqdefault.jpg',
				views: '8.6 млн',
				comments: ' 952 тыс',
				averageViewPercentage: '68 %',
				title: 'Как Помочь ДРУГУ Сбежать Из ТЮРЬМЫ ! - Челлендж',
				pubDate: '23 янв. 2021 г.',
				likes: '676 тыс',
			},
			{
				URL: 'https://img.youtube.com/vi/_Yk5l1eR4mw/mqdefault.jpg',
				views: '8.6 млн',
				comments: ' 952 тыс',
				averageViewPercentage: '68 %',
				title: 'Как Помочь ДРУГУ Сбежать Из ТЮРЬМЫ ! - Челлендж',
				pubDate: '23 янв. 2021 г.',
				likes: '676 тыс',
			},
			{
				URL: 'https://img.youtube.com/vi/_Yk5l1eR4mw/mqdefault.jpg',
				views: '8.6 млн',
				comments: ' 952 тыс',
				averageViewPercentage: '68 %',
				title: 'Как Помочь ДРУГУ Сбежать Из ТЮРЬМЫ ! - Челлендж',
				pubDate: '23 янв. 2021 г.',
				likes: '676 тыс',
			},

		],

		updateTime: '20.04.2021',
		updateTimeInMs: 0,
		leftRolicsPerMonth: '12',
		leftRolicsPerMonthShorts: '0',
		leftRolicsPerMonthTotal: '30',

		leftViewsPerMonth: '1,321',
		leftViewsPerMonthTotal: '2',

		leftNewSubscribersPerMonth: '+2,6',
		leftNewSubscribersPerMonthTotal: '5',

		leftViewsTotal: '5,94',
		leftViewsTotalUnits: '',

		leftSubscribersPerDay: '+19,7',
		leftSubscribersPerDayUnits: '',
		views48array: [],
		times48array: [],

		numberOfSubscribers: '27 900 000',
		numberOfViewsPer48Hours: '94 569',

		changeSlideInterval: 0,
		periodOfChangeSlides: 100,

		bestLastVideos: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
		resizeEventTimeout: null,
		svg1: `
<!--                        <ellipse cx="1.75" cy="0.5" rx="1.75" ry="0.5"></ellipse>-->
                        <linearGradient id="linear-gradient" gradientTransform="rotate(90)">
                            <stop offset="0%" stop-color="rgba(47, 180, 255, 0.2)"/>
                            <stop offset="68%" stop-color="rgba(47, 155, 255, 0)"/>
                        </linearGradient>
                        <defs>
                            <symbol id="youtubeRed">
                                <path d="M1.36917 0.156471C1.35304 0.0952941 1.30583 0.0470588 1.24595 0.0305882C1.13656 0 0.698977 0 0.698977 0C0.698977 0 0.261397 0 0.152002 0.0294118C0.0932737 0.0458824 0.0449095 0.0952941 0.0287882 0.156471C0 0.268235 0 0.5 0 0.5C0 0.5 0 0.732941 0.0287882 0.843529C0.0449095 0.904706 0.0921221 0.952941 0.152002 0.969412C0.262548 1 0.698977 1 0.698977 1C0.698977 1 1.13656 1 1.24595 0.970588C1.30583 0.954118 1.35304 0.905882 1.36917 0.844706C1.39795 0.732941 1.39795 0.501176 1.39795 0.501176C1.39795 0.501176 1.3991 0.268235 1.36917 0.156471V0.156471Z"
                                      fill="#FF0000"/>
                                <path d="M0.923529 0.499999L0.559647 0.285881V0.714116L0.923529 0.499999Z"
                                      fill="white"/>
                            </symbol>
                        </defs>
        `,
		svg2: `
<!--                        <ellipse cx="1.75" cy="0.5" rx="1.75" ry="0.5"></ellipse>-->
                        <defs>
                        </defs>
        `,

		newCurrentMonth: { totalDays: 30, currenDay: 12, percentage: 11 },
		noTooltip: false,
	}),
	created() {
		// let data = {};
		// this.numberOfSubscribers = data.totalSubscribers; // 1
		// this.leftRolicsPerMonth = data.views28; // 2
		// this.leftNewSubscribersPerMonth = data.subscribers28; // 3
		// this.leftViewsTotal = viewsTotal; // 4
		// this.leftSubscribersPerDay = subscribers7; // 5
		// this.    views48array; // 6
		// this.    topViews; // 7
		document.querySelector('meta[name="viewport"]').setAttribute("content",
			//  'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0');
			'width=device-width, initial-scale=1.0');


	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateGraphs);
	},
	beforeMount() {
		console.log('beforeMount');
		document.getElementById('app').style.visibility = 'hidden';
		document.getElementById('app').style.opacity = 0;
		setTimeout(() => {
			document.getElementById('app').style.visibility = 'visible';
			document.getElementById('app').style.transition = 'opacity 5s';
			document.getElementById('app').style.opacity = 1;
		}, 2000)
	},
	beforeCreate() {


		let t = localStorage.getItem("whiteTheme");
		if (t) document.querySelector('html').classList.add('white');


		console.log('coo');
		// console.log(Cookies.get('showSiteOnTV'));
		// console.log(Cookies.get('showSiteOnTV') - 0);
		let u = Cookies.get('user');
		let p = Cookies.get('pp');

		if (!u || !p) location.href = location.origin;

		this.showSiteOnTV = Cookies.get('showSiteOnTV') - 0;


		// console.log(this.showSiteOnTV);
		// console.log(typeof this.showSiteOnTV);

		// console.log(u, JSON.parse(p).map(el => String.fromCharCode(el - 1)).join(''));

		// fb.auth().signInWithEmailAndPassword(this.$route.params.user, this.$route.params.pass)
		fb.auth().signInWithEmailAndPassword(u,
			JSON.parse(p).map(el => String.fromCharCode(el - 1)).join(''))
			.then(() => {
				let user = u.toLowerCase();

				if (!user) return;

				this.user = user;

				fb.firestore().collection('usersRights').doc(user).get().then(doc => {
					console.log('=============================');
					console.log(doc.data());
					if (!location.host.includes('test') && !doc.data().monitorsUser) this.$router.push({ name: 'Home' })
					if (location.host.includes('test') && !doc.data().testMonitorsUser) this.$router.push({ name: 'Home' })


					this.canSetGoals = [
						'kosta232mentorweb@gmail.com',
					].includes(user)
						|| doc.data().monitorGoalsEditor;

				}).catch(e => console.log(e));


			})
			.catch((err) => {
				console.log(err);
				location.href = location.origin;
			});

	},

	mounted() {


		console.log('mounted');

		this.showSiteOnTV = Cookies.get('showSiteOnTV') ? Cookies.get('showSiteOnTV') - 0 : 0;

		// console.log('params:', this.$route.params);
		// if (!this.$route.params.user || !this.$route.params.pass) location.href = location.origin;


		function reformatDate(s) {
			// console.log(s);
			let x = s.split(' ');
			// '01 янв. 2020 г.'
			let c = new Date('');
			// let c = new Date('2021-05-31');
			let m;
			switch (x[1]) {
				case 'янв.':
					m = 0;
					break;
				case 'февр.':
					m = 1;
					break;
				case 'мар.':
					m = 2;
					break;
				case 'апр.':
					m = 3;
					break;
				case 'мая':
					m = 4;
					break;
				case 'июн.':
					m = 5;
					break;
				case 'июл.':
					m = 6;
					break;
				case 'авг.':
					m = 7;
					break;
				case 'сент.':
					m = 8;
					break;
				case 'окт.':
					m = 9;
					break;
				case 'нояб.':
					m = 10;
					break;
				case 'дек.':
					m = 11;
					break;
				default:
					m = -1;
			}
			c.setFullYear(parseInt(x[2]));
			c.setMonth(m);
			c.setDate(x[0]);
			// c.setDate(c.getDate() - 3);
			// let res = String(c.getDate()).padStart(2, "0") + '.' + String(c.getMonth() + 1).padStart(2, "0") + '.' + c.getFullYear();
			let res = String(c.getDate()).padStart(2, "0") + '.' + String(c.getMonth() + 1).padStart(2, "0") + '.' + c.getFullYear();
			// console.log(res);
			return res;
		}


		fb.firestore().collection('monitorData').doc('goalsAndSettings').onSnapshot(snapshot => {

			const doc = snapshot.data();
			this.formPeriodOfChangeSlides = doc.formPeriodOfChangeSlides;
			this.firstGraphDays = doc.firstGraphDays;
			this.formLeftNewSubscribersPerMonthTotal = doc.formLeftNewSubscribersPerMonthTotal;
			this.formLeftViewsPerMonthTotal = doc.formLeftViewsPerMonthTotal;
			this.formLeftRolicsPerMonthTotal = doc.formLeftRolicsPerMonthTotal;
			this.formCurrentMonthViewsDurationTotal = doc.formCurrentMonthViewsDurationTotal;
			this.formCurrentMonthPublishedViewsDurationTotal = doc.formCurrentMonthPublishedViewsDurationTotal;

			this.periodOfChangeSlides = this.formPeriodOfChangeSlides;
			this.leftNewSubscribersPerMonthTotal = this.formLeftNewSubscribersPerMonthTotal;
			this.leftViewsPerMonthTotal = this.formLeftViewsPerMonthTotal;
			this.leftRolicsPerMonthTotal = this.formLeftRolicsPerMonthTotal;
			this.currentMonthViewsDurationTotal = this.formCurrentMonthViewsDurationTotal;
			this.currentMonthPublishedViewsDurationTotal = this.formCurrentMonthPublishedViewsDurationTotal;

		});


		fb.firestore().collection('monitorData').doc('monitor').onSnapshot(snapshot => {

			const doc = snapshot.data();
			console.log(doc);

			this.noTooltip = doc.noTooltip;

			// this.firstDayOfMonth = doc.firstDayOfMonth;
			this.firstDayOfMonth = false;

			const isFirstRolikShort = doc.last4videos[0].short ? 1 : 0;

			this.$set(this.firstBottomRolic, 'duration', doc.last4videos[0].text[0 + isFirstRolikShort]);
			{
				let id = doc.last4videos[0].src.split('?')[0].match(/\/\/.+?\/.+?\/(.+?)\/mq/)?.[1]
					?? doc.last4videos[0].src.split('?')[0].match(/vi\/(.+?)\/default.jpg/)[1];
				let src = 'https://img.youtube.com/vi/' + id + '/mqdefault.jpg'
				this.$set(this.firstBottomRolic, 'URL', src);
			}
			if (doc.noTooltip) {
				this.$set(this.firstBottomRolic, 'views', doc.last4videos[0].text[8]);
			}

			this.$set(this.firstBottomRolic, 'comments', doc.last4videos[0].text[9 + isFirstRolikShort]);
			// this.$set(this.firstBottomRolic, 'averageViewPercentage', doc.last4videos[0].averageViewPercentage);
			// this.$set(this.firstBottomRolic, 'averageViewDuration', doc.last4videos[0].averageViewDuration);
			this.$set(this.firstBottomRolic, 'averageViewDuration', doc.fbrAverageViewDuration);
			this.$set(this.firstBottomRolic, 'title', doc.last4videos[0].text[1 + isFirstRolikShort]);
			this.$set(this.firstBottomRolic, 'pubDate', doc.last4videos[0].text[6 + isFirstRolikShort]);
			let resArr = doc.last4videos[0].text[11 + isFirstRolikShort].split(' ')
				.filter(el => !isNaN(parseInt(el)));
			this.$set(this.firstBottomRolic, 'likes', resArr.join(' '));

			for (let i in doc.topViews) {
				let v = doc.topViews[i];
				// console.log(i, v[i]);
				this.$set(this.bestLastVideos[i], 'title', v.title);
				this.$set(this.bestLastVideos[i], 'views', v.views);
				this.$set(this.bestLastVideos[i], 'highlighted', v.highlighted);
				this.$set(this.bestLastVideos[i], 'videoURL', v.videoURL.split('?')[0]);
			}

			for (let i = 0; i < 3; i++) {

				const isRolikShort = doc.last4videos[i + 1].short ? 1 : 0;

				this.$set(this.threeBottomRolics[i], 'duration', doc.last4videos[i + 1].text[0 + isRolikShort]);
				{
					let id = doc.last4videos[i + 1].src.split('?')[0].match(/\/\/.+?\/.+?\/(.+?)\/mq/)?.[1]
						?? doc.last4videos[i + 1].src.split('?')[0].match(/vi\/(.+?)\/default.jpg/)[1];
					let src = 'https://img.youtube.com/vi/' + id + '/mqdefault.jpg'
					this.$set(this.threeBottomRolics[i], 'URL', src);
				}
				// this.$set(this.threeBottomRolics[i], 'URL', doc.last4videos[i + 1].src.split('?')[0]);
				this.$set(this.threeBottomRolics[i], 'views', doc.last4videos[i + 1].text[8 + isRolikShort]);
				this.$set(this.threeBottomRolics[i], 'comments', doc.last4videos[i + 1].text[9 + isRolikShort]);
				this.$set(this.threeBottomRolics[i], 'averageViewPercentage', doc.last4videos[i + 1].averageViewPercentage);
				this.$set(this.threeBottomRolics[i], 'averageViewDuration', doc.last4videos[i + 1].averageViewDuration);
				this.$set(this.threeBottomRolics[i], 'title', doc.last4videos[i + 1].text[1 + isRolikShort]);
				this.$set(this.threeBottomRolics[i], 'pubDate', doc.last4videos[i + 1].text[6 + isRolikShort]);
				let resArr = doc.last4videos[i + 1].text[11 + isRolikShort].split(' ')
					.filter(el => !isNaN(parseInt(el)));
				this.$set(this.threeBottomRolics[i], 'likes', resArr.join(' '));
			}

			this.viewsTotalFullNumber = doc.viewsTotalFullNumber.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

			this.numberOfSubscribers = doc.totalSubscribers; // 1
			// this.leftViewsPerMonth = doc.views28.split(' ')[0]; // 2

			{
				let h = parseFloat(doc.views28.replace(',', '.'));
				if (doc.views28.includes('тыс')) h *= 1000;
				if (doc.views28.includes('млн')) h *= 1000000;
				let k = 1;
				let t = '';

				if (h >= 1000000) {
					k = 1000000;
					// eslint-disable-next-line
					// t = ' млн.'
					// t = ''
					t = ' M'
				} else if (h >= 1000) {
					k = 1000;
					t = ' K'
				}

				// let res =  (h / k).toFixed(0) + t
				// this.leftNewSubscribersPerMonth = (h / k).toFixed(2) + t;
				this.leftViewsPerMonth = ((h / k).toFixed(1) + t).replace('.', ',');
			}
			{
				let h = parseFloat(doc.subscribers28.replace(',', '.'));
				if (doc.subscribers28.includes('тыс')) h *= 1000;
				if (doc.subscribers28.includes('млн')) h *= 1000000;
				let k = 1;
				let t = '';

				if (h >= 1000000) {
					k = 1000000;
					// eslint-disable-next-line
					// t = ' млн.'
					t = ' M'
				} else if (h >= 1000) {
					k = 1000;
					t = ' K'
				}

				// let res =  (h / k).toFixed(0) + t
				// this.leftNewSubscribersPerMonth = (h / k).toFixed(2) + t;
				this.leftNewSubscribersPerMonth = ((h / k).toFixed(1) + t).replace('.', ',');
			}

			// this.leftNewSubscribersPerMonth = doc.subscribers28.split(' ')[0] + ' ' + doc.subscribers28.split(' ')[1].replace('млн', 'M').replace('млрд', 'B').replace('тыс.', 'K'); // 3
			this.leftViewsTotal = doc.viewsTotal.split(' ')[0] + ' ' + doc.viewsTotal.split(/\u00A0/)[1]?.replace('млн', 'M')?.replace('млрд', 'B'); // 4


			this.leftViewsTotalUnits = ''; // 4
			this.leftSubscribersPerDay =
				(parseInt(doc.subscribers7) >= 0 ? '+' : '–')
				+ doc.subscribers7; // 5
			// this.leftSubscribersPerDayUnits = doc.subscribers7.split(' ')[1];
			this.views48array = doc.views48array; // 6
			this.times48array = doc.dates48array; // 6
			// this.    topViews; // 7

			this.pubDates = doc.pubDates.map(el => reformatDate(el).split('.').slice(0, 2).join('.'));
			this.pubDatesSH = doc.pubDatesSH.map(el => el);
			// console.log('pubDates', this.pubDates);

			// this.numberOfViewsPer48Hours = doc.views48;

			this.subscribers28increment = doc.subscribers28increment;
			this.subscribers28incrementBadData = doc.subscribers28incrementBadData;
			this.subscribers28incrementObject = doc.subscribers28incrementObject;

			console.log('subscribers28incrementBadData', this.subscribers28incrementBadData);

			console.log('subscribers28incrementObject', this.subscribers28incrementObject);

			let newObj = {};

			for (let dateProp in this.subscribers28incrementObject) {
				if (dateProp.split(',').length > 1) {
					let date = reformatDate(dateProp.split(',')[1].trim());
					date = date.split('.').splice(0, 2)
					newObj[date.join('.')] = this.subscribers28incrementObject[dateProp]
				}
			}

			console.log({ newObj });

			this.timeStamp = doc.timeStamp;
			this.updateTime = new Date(doc.timeStamp).toLocaleDateString()
				+ ' (' + new Date(doc.timeStamp).toLocaleTimeString().split(':').splice(0, 2).join(':') + ')';


			this.pubTimes = [];
			for (let v of doc.last4videos) this.pubTimes.push(v.pubTime);

			if (!doc.noTooltip) {
				if (doc.dataLastEffect.length === 9) {
					let temp = doc.dataLastEffect.splice(1, 1);
					doc.dataLastEffect.splice(1, 0, temp[0], temp[0]);
				}

				this.dataPeriodLastEffect = doc.dataLastEffect[0];
				this.slide4arr = [];

				this.slide4arr.push(doc.dataLastEffect[0]);
				this.slide4arr.push(doc.dataLastEffect[2]);
				this.slide4arr.push(doc.dataLastEffect[3]);
				this.slide4arr.push(doc.dataLastEffect[4]);
				// this.slide4arr.push(doc.dataLastEffect[5]);
				this.slide4arr.push(doc.dataLastEffect[5]);
				this.slide4arr.push(doc.tooltips[0]);
				this.slide4arr.push(doc.dataLastEffect[6]);
				// this.slide4arr.push(doc.dataLastEffect[7]); ///////
				if (!isFirstRolikShort) this.slide4arr.push(doc.fbrCTR); else this.slide4arr.push(doc.dataLastEffect[7]);
				// this.slide4arr.push(doc.tooltips[1]);  // ctr description
				this.slide4arr.push(doc.tooltips[1] && (doc.tooltips[1].includes('CTR') || doc.tooltips[1].includes('значок')) ? doc.tooltips[1] : '—');  // ctr description
				// this.slide4arr.push('—');
				this.slide4arr.push(doc.dataLastEffect[8]);
				if (isFirstRolikShort)
					this.slide4arr.push(doc.dataLastEffect[9]);
				else
					this.slide4arr.push(doc.fbrAverageViewDuration);
				// this.slide4arr.push(doc.tooltips[2]);
				this.slide4arr.push(doc.tooltips[2] && doc.tooltips[2].match(/\d:\d/g) ? doc.tooltips[2] : '—');  // ctr description
				this.slide4arr.push(doc.fbrTotalViewDurationWithDescription.split('\n')[0]);
				this.slide4arr.push(doc.fbrTotalViewDurationWithDescription.split('\n')[1]);
				this.slide4arr.push(doc.fbrTotalViewDurationWithDescription.split('\n')[2]);

				this.slide4arrIcons = [];
				this.slide4arrIcons.push(...doc.dataLastEffectIcons);


				{
					let h = parseFloat(this.slide4arr[4].replace(',', '.'));
					if (this.slide4arr[4].includes('тыс')) h *= 1000;
					if (this.slide4arr[4].includes('млн')) h *= 1000000;

					this.$set(this.firstBottomRolic, 'views', h);
				}
			}
			{
				let h = parseFloat(doc.curMonthViewsOfALLVideos.replace(',', '.'));
				if (doc.curMonthViewsOfALLVideos.includes('тыс')) h *= 1000;
				if (doc.curMonthViewsOfALLVideos.includes('млн')) h *= 1000000;

				this.currentMonthViewsDuration = h;
			}


			// this.slide4arrIcons.push(...doc.dataLastEffectIcons.map(el => el.replace('viewBox=', 'fill="green" viewBox=')));


			this.currentMonthPublishedViewsDuration = doc.currentMonthPublishedTotalViews;


			this.subscribersArr = [];
			this.subscribersArr.push(...doc.subscribersArray);


			this.leftRolicsPerMonth = doc.curMonthNumberOfVideos;
			this.leftRolicsPerMonthShorts = doc.curMonthNumberOfVideosShorts;

			this.updateGraphs();
			document.querySelector('.wrapper').style.display = 'flex';

			// console.log(this.subscribersArr[26], this.subscribersArr[25]);
			let subscribersVelocity = (this.subscribersArr[26] - this.subscribersArr[25]) / 86400;
			// console.log(this.views48array.reduce((acc, el) => acc + el));
			let views48Velocity = this.views48array.reduce((acc, el) => acc + el) / (this.views48array.length * 60 * 60);

			// console.log({subscribersVelocity, views48Velocity});

			clearInterval(this.odometerSetInterval);
			this.odometerSetInterval = setInterval(() => {
				this.num += 7;
				// this.numberOfViewsPer48Hours = parseInt(this.numberOfViewsPer48Hours.replace(/ /g,'').replace(/\00A0/g,'')) + 3;
				// this.numberOfSubscribers+=9;
				this.numberOfSubscribers =
					parseInt(this.numberOfSubscribers.replace(/\s/g, '').replace(/\u00A0/g, ''))
					+ Math.round(subscribersVelocity * 4 * 1.5 * Math.random()) + '';
				this.numberOfViewsPer48Hours =
					parseInt(this.numberOfViewsPer48Hours.replace(/\s/g, '').replace(/\u00A0/g, ''))
					+ Math.round(views48Velocity * 4 * 1.5 * Math.random()) + '';
			}, 4000);

			setTimeout(() => {
				console.log('111111111111111111111');
				const text = this.makeContentForSlack();
				console.log(text);

				// if (['kosta232mentorweb@gmail.com'].includes(Cookies.get('user')))
				fb.firestore().collection('monitorData').doc('monitorForSlackData').set({ text, timeStamp: +new Date() })

			}, 11000);

			{
				let dt = new Date(this.timeStamp);

				if (dt.getDate() === 1) dt = new Date(this.timeStamp - 86400000);
				else if (dt.getDate() === 2 && dt.getHours() < 12) dt = new Date(this.timeStamp - 2 * 86400000);
				else dt.setHours(dt.getHours() - 36);
				let month = dt.getMonth() + 1;
				let year = dt.getFullYear();
				let daysInMonth = new Date(year, month, 0).getDate();
				// let currentDay = new Date(this.timeStamp).getDate() - 1;

				let currentDay = dt.getDate();

				this.newCurrentMonth.currenDay = currentDay;
				this.newCurrentMonth.totalDays = daysInMonth;
				this.newCurrentMonth.percentage = (currentDay / daysInMonth * 100).toFixed(0);
			}

		});


		window.addEventListener('resize', this.updateGraphs);


		setTimeout(() => {
			document.querySelector('.slide.active').classList.remove('opacity0');
		}, 200);

		this.changeSlideInterval = setInterval(() => {
			this.changeSlide('next');
		}, this.periodOfChangeSlides * 1000);

	},
	computed: {

		leftRolicsPerMonthPercentage() {
			let x = parseInt(this.leftRolicsPerMonth) + parseInt(this.leftRolicsPerMonthShorts);
			let t = parseInt(this.leftRolicsPerMonthTotal);

			return {
				value: x && t ? Math.round(x / t * 100) + ' %' : '',
				color: this.percentageColor(x, t, 1)
			}
		},

		leftViewsPerMonthPercentage() {
			// console.log('11111111111111111111111');
			// console.log(this.leftViewsPerMonth);
			// console.log(this.leftViewsPerMonthTotal);
			let x = parseFloat(this.leftViewsPerMonth.replace(',', '.'));
			if (this.leftViewsPerMonth.includes('K')) x /= 1000
			let t = parseFloat(this.leftViewsPerMonthTotal.replace(',', '.'));
			// console.log(x, t);
			return {
				value: x && t ? Math.round(x / t * 100) + ' %' : '',
				color: this.percentageColor(x, t)
			}
		},

		leftNewSubscribersPerMonthPercentage() {
			let x = parseFloat(this.leftNewSubscribersPerMonth.replace(',', '.'));
			if (this.leftNewSubscribersPerMonth.includes('K')) x *= 1000;
			else if (this.leftNewSubscribersPerMonth.includes('M')) x *= 1000000;
			let t = parseFloat(this.leftNewSubscribersPerMonthTotalSTRING.replace(',', '.'));
			if (this.leftNewSubscribersPerMonthTotalSTRING.includes('K')) t *= 1000;
			else if (this.leftNewSubscribersPerMonthTotalSTRING.includes('M')) t *= 1000000;
			return {
				value: x && t ? Math.round(x / t * 100) + ' %' : '',
				color: this.percentageColor(x, t)
			}
		},

		currentMonthViewsDurationPercentage() {
			let x = this.currentMonthViewsDuration;
			// if (this.currentMonthViewsDuration.includes('K')) x *= 1000;
			// else if (this.currentMonthViewsDuration.includes('M')) x *= 1000000;
			x /= 1000000;
			let t = parseFloat(this.currentMonthViewsDurationTotalSTRING.replace(',', '.'));
			// if (this.currentMonthViewsDurationTotalSTRING.includes('K')) t *= 1000;
			// else if (this.currentMonthViewsDurationTotalSTRING.includes('M')) t *= 1000000;
			return {
				value: x && t ? Math.round(x / t * 100) + ' %' : '',
				color: this.percentageColor(x, t)
			}
		},

		currentMonthPublishedViewsDurationPercentage() {
			let x = parseFloat(this.currentMonthPublishedViewsDuration);
			// if (this.currentMonthPublishedViewsDuration.includes('K')) x *= 1000;
			// else if (this.currentMonthPublishedViewsDuration.includes('M')) x *= 1000000;
			x /= 1000000;
			let t = parseFloat(this.currentMonthPublishedViewsDurationTotal);
			// t *= 1000000;
			return {
				value: x && t ? Math.round(x / t * 100) + ' %' : '',
				color: this.percentageColor(x, t)
			}
		},

		numberOfViewsPer48HoursNUMBER() {
			return parseInt(this.numberOfViewsPer48Hours.replace(/\s/g, '').replace(/\u00A0/g, ''));
		},

		numberOfSubscribersNUMBER() {
			return parseInt(this.numberOfSubscribers.replace(/\s/g, '').replace(/\u00A0/g, ''));
		},

		leftNewSubscribersPerMonthTotalSTRING() {
			// in M
			return ((parseInt(this.leftNewSubscribersPerMonthTotal) / 1000).toFixed(1) + ' M').replace('.', ',');
			// in K
			// return ((parseInt(this.leftNewSubscribersPerMonthTotal) / 1).toFixed(0) + ' K').replace('.', ',');
		},

		currentMonthViewsDurationTotalSTRING() {
			return ((parseInt(this.currentMonthViewsDurationTotal)).toFixed(0) + ' M').replace('.', ',');
		},

		currentMonthPublishedViewsDurationTotalSTRING() {
			return ((typeof this.currentMonthPublishedViewsDurationTotal === 'string'
				? this.currentMonthPublishedViewsDurationTotal.replace('.', ',')
				: this.currentMonthPublishedViewsDurationTotal) + ' M');
		},
	},

	watch: {},
	methods: {

		changeTheme() {
			console.log('changeTheme');
			let html = document.querySelector('html');
			html.classList.toggle('white');

			if (html.classList.contains('white')) localStorage.setItem("whiteTheme", "1");
			else localStorage.removeItem("whiteTheme");
		},

		makeContentForSlack() {
			let text = 'Показатели на ' + new Intl.DateTimeFormat('ru', {
				day: 'numeric',
				month: 'long',
				year: 'numeric'
			}).format(new Date(this.timeStamp - 86400000)).replace('г.', 'года:\n');

			let dt = new Date(this.timeStamp);

			if (dt.getDate() === 1) dt = new Date(this.timeStamp - 86400000);
			else if (dt.getDate() === 2 && dt.getHours() < 12) dt = new Date(this.timeStamp - 2 * 86400000);
			else dt.setHours(dt.getHours() - 36);

			let month = dt.getMonth() + 1;
			let year = dt.getFullYear();
			let daysInMonth = new Date(year, month, 0).getDate();
			// let currentDay = new Date(this.timeStamp).getDate() - 1;

			let currentDay = dt.getDate();

			text += '1. Дней прошло: ' + currentDay + ' из ' + daysInMonth
				+ ' (' + (currentDay / daysInMonth * 100).toFixed(0) + '/100%)\n';

			let videos = this.leftRolicsPerMonth;
			let videosAll = this.leftRolicsPerMonth - 0 + (this.leftRolicsPerMonthShorts - 0);
			let videosTotal = parseInt(this.leftRolicsPerMonthTotal);
			text += '2. Выпущено: ' + videos + ' длинных и ' + this.leftRolicsPerMonthShorts + ' коротких из ' + videosTotal
				+ ' роликов (' + (videosAll / videosTotal * 100).toFixed(0) + '/100%)\n';

			let viewsString = this.leftViewsPerMonth;
			let viewsTotalString = this.leftViewsPerMonthTotal;

			let views = parseFloat(viewsString.replace(',', '.'));
			if (viewsString.includes('K')) views *= 1000;
			else if (viewsString.includes('M')) views *= 1000000;

			let viewsTotal = parseFloat(viewsTotalString.replace(',', '.'));
			viewsTotal *= 1000000;

			text += '3. Просмотры: ' + views.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
				+ ' из ' + viewsTotal.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
				+ ' (' + (views / viewsTotal * 100).toFixed(0) + '/100%)\n';

			let viewDuration = this.currentMonthViewsDuration;

			let viewDurationTotal = parseFloat(this.currentMonthViewsDurationTotalSTRING);
			if (this.currentMonthViewsDurationTotalSTRING.includes('K')) viewDurationTotal *= 1000;
			else if (this.currentMonthViewsDurationTotalSTRING.includes('M')) viewDurationTotal *= 1000000;

			console.log({ viewDuration });
			console.log({ viewDurationTotal });

			text += '4. Общее время просмотра: ' + viewDuration.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
				+ ' из ' + viewDurationTotal.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
				+ ' (' + (viewDuration / viewDurationTotal * 100).toFixed(0) + '/100%)\n';

			let newSubscribers = parseFloat(this.leftNewSubscribersPerMonth.replace(',', '.'));
			if (this.leftNewSubscribersPerMonth.includes('K')) newSubscribers *= 1000;
			else if (this.leftNewSubscribersPerMonth.includes('M')) newSubscribers *= 1000000;
			newSubscribers = Math.round(newSubscribers);

			let newSubscribersTotal = parseFloat(this.leftNewSubscribersPerMonthTotalSTRING.replace(',', '.'));
			if (this.leftNewSubscribersPerMonthTotalSTRING.includes('K')) newSubscribersTotal *= 1000;
			else if (this.leftNewSubscribersPerMonthTotalSTRING.includes('M')) newSubscribersTotal *= 1000000;

			console.log('newSubscribers', newSubscribers);

			text += '5. Новых подписчиков: ' + newSubscribers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
				+ ' из ' + newSubscribersTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
				+ ' (' + (newSubscribers / newSubscribersTotal * 100).toFixed(0) + '/100%)\n';

			console.log(text);
			navigator.clipboard.writeText(text);
			return text;
		},

		percentageColor(x, t, dayShift = 0) {
			let endDayCurrentMonth = 28;
			let endDaysArray = [28, 29, 30, 31, 32];
			let isPrevMonth = false;
			let endMonthColorRule = false;
			for (let i in endDaysArray) {
				let endDay = endDaysArray[i];

				let currentMonth = new Date().getMonth();
				if (new Date().getDate() === 1 || (new Date().getDate() === 2 && new Date(this.timeStamp).getHours() < 12)) {
					isPrevMonth = true;
					currentMonth = (currentMonth + 11) % 12;
					endMonthColorRule = true;
				}

				if (new Date(new Date(new Date().setMonth(currentMonth)).setDate(endDay)).getMonth() != currentMonth) {
					endDayCurrentMonth = endDaysArray[i - 1];
					endMonthColorRule = true;
					break;
				}
			}
			console.log(endMonthColorRule); //убрать потом
			let valuePerDay = t / endDayCurrentMonth;
			// console.log({valuePerDay});
			let currentDay = new Date().getDate();
			let color = 'red';
			// if (new Date().toLocaleTimeString().split(':')[0] - 0 < 12) currentDay--;
			currentDay = currentDay - 1 + dayShift;

			if (isPrevMonth) {
				currentDay = endDayCurrentMonth;
			}
			// console.log({currentDay});
			if (x / (valuePerDay * currentDay) > 0.999) color = '#58D84D'; //green
			// if ( x / ( valuePerDay * currentDay ) > 0.899 && endMonthColorRule ) color = '#58D84D'; //green
			else if (x / (valuePerDay * currentDay) > 0.899) color = 'yellow';
			// else
			// console.log(valuePerDay * currentDay, x);
			return color;
		},

		addMK(n, x = 3) {
			let suffix = '';
			let k = 1000;
			let m = 1000000;
			let b = 1000000000;
			let coef = 1;
			if (typeof n === 'string') n = parseInt(n.replace(/\s/g, '').replace(/\u00A0/g, ''))
			if (n > b) {
				coef = b;
				suffix = ' B';
			} else if (n > m) {
				coef = m;
				suffix = ' M';
			} else if (n > k) {
				coef = k;
				suffix = ' K';
			}

			if (coef === 1) return n;

			return ((n / coef).toFixed(x) + suffix).replace('.', ',');
		},


		logout() {
			location.href = location.origin;
		},

		showModal() {
			if (!this.canSetGoals) return;
			this.$refs.myModalAround.style.display = 'block';
			// this.$refs.myModal1.style.display = 'none';
			this.$refs.myModal2.style.display = 'block';

			// this.firstGraphDays = this.firstGraphDays;
			this.formPeriodOfChangeSlides = this.periodOfChangeSlides;
			this.formLeftNewSubscribersPerMonthTotal = this.leftNewSubscribersPerMonthTotal;
			this.formLeftViewsPerMonthTotal = this.leftViewsPerMonthTotal;
			this.formLeftRolicsPerMonthTotal = this.leftRolicsPerMonthTotal;
			this.formCurrentMonthViewsDurationTotal = this.currentMonthViewsDurationTotal;
			this.formCurrentMonthPublishedViewsDurationTotal = this.currentMonthPublishedViewsDurationTotal;
			document.querySelector('.myModal-2').style.height = document.querySelector('.myModal2container').getClientRects()[0].height * this.scaleFactor + 40 + 'px';
		},

		cancelFromModal() {
			this.hideModals();
		},

		saveFromModal() {
			fb.firestore().collection('monitorData').doc('goalsAndSettings').set({
				formPeriodOfChangeSlides: this.formPeriodOfChangeSlides,
				firstGraphDays: this.firstGraphDays,
				formLeftNewSubscribersPerMonthTotal: this.formLeftNewSubscribersPerMonthTotal,
				formLeftViewsPerMonthTotal: this.formLeftViewsPerMonthTotal,
				formLeftRolicsPerMonthTotal: this.formLeftRolicsPerMonthTotal,
				formCurrentMonthViewsDurationTotal: this.formCurrentMonthViewsDurationTotal,
				formCurrentMonthPublishedViewsDurationTotal: this.formCurrentMonthPublishedViewsDurationTotal,
			}, { merge: false }).then(() => {
				location.reload();
				this.hideModals();
			}).catch(err => {
				alert(err);
				location.reload();
			});

		},

		keyUpIntegerInput(event) {
			// console.log(event);
			// console.log(event.target.min);
			// console.log(event.target.max);
			event.target.value = String(event.target.value).split('').filter(el => !isNaN(parseInt(el))).join('');
			// this.$emit(even, val);
			// let min = parseInt(event.target.min);
			// let max = parseInt(event.target.max);
			let step = parseInt(event.target.step) || 1;
			let val = parseInt(event.target.value);
			if (!isNaN(val)) {
				// if (event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight") {
				// if (val > max) val = max;
				// if (val < min) val = min;
				// }
				if (event.key === "ArrowUp") val += step;
				if (event.key === "ArrowDown") val -= step;
			}
			event.target.value = isNaN(val) ? '' : val;
			event.target.dispatchEvent(new Event('input'));

		},

		keyUpFloatInput(event) {
			// console.log(event);
			// console.log(event.target.min);
			// console.log(event.target.max);
			console.log('event.target.value', event.target.value);
			event.target.value = String(event.target.value).split('').filter(el => !isNaN(parseInt(el)) || el === '.').join('');
			console.log('event.target.value', event.target.value);
			// this.$emit(even, val);
			// let min = parseInt(event.target.min);
			// let max = parseInt(event.target.max);
			let step = parseFloat(event.target.step) || 1;
			let val = parseFloat(event.target.value);
			if (!isNaN(val)) {
				// if (event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight") {
				// if (val > max) val = max;
				// if (val < min) val = min;
				// }
				if (event.key === "ArrowUp") val += step;
				if (event.key === "ArrowDown") val -= step;
			}
			if (event.target.value.slice(-1) !== '.') event.target.value = isNaN(val) ? '' : String(val);
			event.target.dispatchEvent(new Event('input'));

		},

		blurIntegerInput(event) {
			let min = parseInt(event.target.min);
			let max = parseInt(event.target.max);
			let val = parseInt(event.target.value);
			if (isNaN(val)) val = min;
			if (val > max) val = max;
			if (val < min) val = min;
			event.target.value = val;
			event.target.dispatchEvent(new Event('input'));
		},

		blurFloatInput(event) {
			let min = parseInt(event.target.min);
			let max = parseInt(event.target.max);
			let val = parseFloat(event.target.value);
			if (isNaN(val)) val = min;
			if (val > max) val = max;
			if (val < min) val = min;
			event.target.value = val;
			event.target.dispatchEvent(new Event('input'));
		},

		hideModals() {
			this.$refs.myModalAround.style.display = 'none';
			// this.$refs.myModal1.style.display = 'none';
			this.$refs.myModal2.style.display = 'none';
			// this.$refs.myModal3.style.display = 'none';
			// this.$refs.myModal4.style.display = 'none';
			// this.$refs.myModal6.style.display = 'none';
			// this.$refs.myModal7.style.display = 'none';
		},

		async updateGraphs() {
			console.log('updateGraphs');

			if (this.animState) await new Promise(res => {
				setTimeout(res, 3000);
			})

			clearInterval(this.changeSlideInterval);
			this.changeSlideInterval = setInterval(() => {
				this.changeSlide('next');
			}, this.periodOfChangeSlides * 1000)

			// function delayPro(ms) {
			//     return new Promise(resolve => {
			//         setTimeout(resolve, ms);
			//     })
			// }

			{
				let svg1 = document.getElementById('graphSlide1');
				svg1.innerHTML = this.svg1;

				let svg2 = document.getElementById('graphSlide2');
				svg2.innerHTML = this.svg2;

			}

			[...document.querySelectorAll('.slide')].forEach(el => {
				el.classList.remove('active');
				el.classList.add('opacity0');

			});


			document.querySelector('.slide-1').classList.add('active');

			if (this.resizeEventTimeout) clearTimeout(this.resizeEventTimeout);

			// while (!document.querySelector('.slide-1').classList.contains('active')) {
			//     await delayPro(500);
			// }


			this.resizeEventTimeout = setTimeout(() => {

				let timeStamp = this.timeStamp;

				let svg = document.getElementById('graphSlide1')
				svg.innerHTML = this.svg1;
				// console.log(svg);

				let temp1 = document.querySelector('.slide1-title');
				let h = document.querySelector('.slidePrevNextButton').getClientRects()[0].height * this.scaleFactor;
				let heightSVG = 0.92 * (h - (temp1.getClientRects()[0].top * this.scaleFactor + temp1.getClientRects()[0].height * this.scaleFactor));
				// console.log({heightSVG}, svg.getClientRects()[0].width);
				svg.setAttribute('height', heightSVG);

				let path = 'M';
				// let graphLength = 3.5 ; * svg.getClientRects()[0].width / 1270;
				let graphLength = svg.getClientRects()[0].width * this.scaleFactor / heightSVG;
				// let graphHeight = 1 /** h / 702*/;
				let graphHeight = 1;
				svg.setAttribute('viewBox', '0 0 ' + graphLength + ' ' + graphHeight);

				// let arr = [...this.subscribersArr].reverse().slice(0, this.firstGraphDays).reverse();


				let arr = [...this.subscribers28increment];
				// arr.push(this.subscribersArr[28] - this.subscribersArr[27]);

				arr = arr.reverse().slice(0, this.firstGraphDays).reverse();
				let pubDates = [...this.pubDates];
				let pubDatesSH = [...this.pubDatesSH];

				// for (let i = 0; i < 20; i++) {
				//     arr.push(Math.floor(Math.random() * 5000000) + 28000000);
				// }

				// console.log(arr);
				let max = Math.max(...arr);
				let min = Math.min(...arr);
				// arr.push(arr[arr.length - 1] + arr[arr.length - 1] - arr[arr.length - 2]);
				arr.push(arr[arr.length - 1]);
				arr.unshift(arr[0]);

				// let h = max - min;
				let circles = [];
				let dates = [];
				for (let i in arr) {
					// console.log(i, typeof i);
					let x = (i - 0.5) / (arr.length - 2) * graphLength * 0.99;
					let y = 0.1 + 0.7 * (graphHeight - (arr[i] - min) / (max - min)) * graphHeight;
					path += '' + /*(i === '0' ? '' : ' ')*/ ' ' + x + ' ' + y;
					circles[i] = { x, y };
					dates[i] = '0' + i + '.05';
					// console.log({x, y});
				}
				let pa = document.createElementNS('http://www.w3.org/2000/svg', 'path');
				pa.setAttribute('d', path + ' ' + (graphLength + 0.1) + ' ' + (graphHeight + 0.5) + ' 0 ' + (graphHeight + 0.5));
				pa.setAttribute('fill', "url(#linear-gradient)");
				pa.setAttribute('stroke', "#2489FF");
				pa.setAttribute('stroke-width', "0.007");
				svg.append(pa);

				for (let i in circles) {
					let c = circles[i];
					let circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
					circle.setAttribute('cx', c.x);
					circle.setAttribute('cy', c.y);
					circle.setAttribute('r', '0.013');
					circle.setAttribute('stroke', "#2489FF");
					circle.setAttribute('stroke-width', "0.006");
					circle.setAttribute('fill', "#fff");
					svg.append(circle);
					let dashedLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
					dashedLine.setAttribute('x1', c.x);
					dashedLine.setAttribute('x2', c.x);
					dashedLine.setAttribute('y2', c.y);
					dashedLine.setAttribute('y1', graphHeight - 0.12);
					dashedLine.setAttribute('stroke', "rgba(255, 255, 255, 0.2)");
					dashedLine.setAttribute('stroke-width', "0.002");
					dashedLine.setAttribute('stroke-dasharray', "0.02 0.03");
					svg.append(dashedLine);

					// зимнее время
					// let currentDate = new Date(timeStamp - ((new Date(timeStamp).getHours() > 12) ? 86400000 : 86400000 * 2));
					// летнее время
					let currentDate = new Date(timeStamp - ((new Date(timeStamp).getHours() > 11) ? 86400000 : 86400000 * 2));

					currentDate.setDate(currentDate.getDate() - (circles.length - 2 - i));
					let date = document.createElementNS('http://www.w3.org/2000/svg', 'text');
					date.setAttribute('x', c.x);
					date.setAttribute('y', graphHeight - 0.07);
					date.setAttribute('style', "text-anchor: middle; font-family: Roboto; font-size: 0.028px; fill: #888; font-weight: 400;");
					// date.innerHTML = '01.05';
					let dateShort = currentDate.toISOString().split('T')[0].split('-').slice(1).reverse().join('.');
					date.innerHTML = dateShort;
					if (i != circles.length - 1) svg.append(date);

					// if (!dateShort in this.subscribers28incrementObject) {
					//
					// }


					let yShift = -0.035;
					if (arr[i] && arr[i - 0 + 1] && arr[i] < arr[i - 0 + 1]) yShift = 0.055;

					let valueOutline = document.createElementNS('http://www.w3.org/2000/svg', 'text');
					valueOutline.setAttribute('x', c.x);
					valueOutline.setAttribute('y', c.y + yShift);
					valueOutline.setAttribute('style', "text-anchor: middle;  font-size: 0.032px; fill: #2489FF; stroke: #040910; stroke-width: 0.015; font-weight: 700;");
					// valueOutline.innerHTML = '01.05';
					valueOutline.innerHTML = (Math.round(arr[i] / 1000).toFixed(1) + ' K').replace('.', ',');
					if (i != circles.length - 1) svg.append(valueOutline);

					let value = document.createElementNS('http://www.w3.org/2000/svg', 'text');
					value.setAttribute('x', c.x);
					value.setAttribute('y', c.y + yShift);
					value.setAttribute('style', "text-anchor: middle;  font-size: 0.032px; fill: #6c9bd2; font-weight: 700;");
					// value.innerHTML = '01.05';
					value.innerHTML = ((arr[i] / 1000).toFixed(1) + ' K').replace('.', ',');
					if (i != circles.length - 1) svg.append(value);

					// bDates);

					// if (Math.random() > 0.5) {
					if (pubDates.includes(date.innerHTML)) {
						const idx = pubDates.findIndex(el => el === date.innerHTML);
						const isShort = pubDatesSH[idx];

						let yt = document.createElementNS('http://www.w3.org/2000/svg', 'use');
						yt.setAttribute('xlink:href', '#youtubeRed');
						yt.setAttribute('x', !isShort ? (c.x - 0.03) / 0.045 : (c.x - 0.015) / 0.025);
						yt.setAttribute('y', (graphHeight - 0.050) / 0.045);
						yt.setAttribute('transform', !isShort ? 'scale(0.045, 0.045)' : 'scale(0.025, 0.045)');
						if (i != circles.length - 1) svg.append(yt);

						// let value = document.createElementNS('http://www.w3.org/2000/svg', 'text');
						// value.setAttribute('x', c.x);
						// value.setAttribute('y', graphHeight - 0.01); //  0.053
						// value.setAttribute('style', "text-anchor: middle;  font-size: 0.015px; fill: white; font-weight: 700;");
						// // value.innerHTML = '01.05';
						// value.innerHTML = 'SHORT';
						// if (i != circles.length - 1 && pubDatesSH[idx]) svg.append(value);
					}

				}

				// вывод инфы 'Youtube уточняет данные за период'

				if (this.subscribers28incrementBadData) {

					let value = document.createElementNS('http://www.w3.org/2000/svg', 'text');
					value.setAttribute('x', 0.45);
					value.setAttribute('y', 0.45);
					value.setAttribute('style', "font-size: 0.072px; fill: red; font-weight: 700;");
					value.innerHTML = 'Youtube уточняет данные за период';
					svg.append(value);

				}

				document.getElementById('graphSlide1').innerHTML += '<use xlink:href="#youtubeRed" x="1.3" y="0.3" transform="scale(0.0005, 0.0005)" />'

				let textAxisYmax = document.createElementNS('http://www.w3.org/2000/svg', 'text');
				textAxisYmax.setAttribute('x', graphLength - 0.02);
				textAxisYmax.setAttribute('y', 0.065);
				textAxisYmax.setAttribute('style', "text-anchor: end; font-family: Roboto; font-size: 0.04px; fill: #888; font-weight: 400;");
				textAxisYmax.innerHTML = this.addMK(max, 1);
				// svg.append(textAxisYmax);

				let textAxisYmid = document.createElementNS('http://www.w3.org/2000/svg', 'text');
				textAxisYmid.setAttribute('x', graphLength - 0.02);
				textAxisYmid.setAttribute('y', graphHeight / 2 - 0.05);
				textAxisYmid.setAttribute('style', "text-anchor: end; font-family: Roboto; font-size: 0.04px; fill: #888; font-weight: 400;");
				textAxisYmid.innerHTML = this.addMK(Math.round((max - min) / 2 + min), 1);
				// svg.append(textAxisYmid);

				let textAxisYmin = document.createElementNS('http://www.w3.org/2000/svg', 'text');
				textAxisYmin.setAttribute('x', graphLength - 0.02);
				textAxisYmin.setAttribute('y', graphHeight - 0.18);
				textAxisYmin.setAttribute('style', "text-anchor: end; font-family: Roboto; font-size: 0.04px; fill: #888; font-weight: 400;");
				textAxisYmin.innerHTML = this.addMK(min, 1);
				// svg.append(textAxisYmin);


				//svg2

				let svg2 = document.getElementById('graphSlide2');
				svg2.innerHTML = this.svg2;
				// let temp2 = document.querySelector('.slide2-title');
				// console.log({temp2});
				// let h2 = document.querySelector('.slidePrevNextButton').getClientRects()[0].height;
				// let heightSVG2 = 0.9 * (h2 - (temp2.getClientRects()[0].top + temp2.getClientRects()[0].height));
				// console.log({heightSVG2});
				svg2.setAttribute('height', heightSVG);
				svg2.setAttribute('viewBox', '0 0 ' + graphLength + ' ' + graphHeight);


				// let views48 = [...this.views48array].slice(24);
				let views48 = [...this.views48array];
				// let times48 = [...this.times48array].slice(24);
				let times48 = [...this.times48array];

				// console.log(views48, times48);

				// let dayStarted = false;


				// this.numberOfViewsPer48Hours = String(views48.reduce((acc, el) => acc + el));
				// console.log(views48, times48);
				// for (let i = 0; i < 48; i++) {
				//     views48.push(Math.floor(Math.random() * 700000) + 300000);
				// }

				let minBar = Math.min(...views48);
				let maxBar = Math.max(...views48);
				// console.log({minBar, maxBar});

				let numViews48 = 0;

				// this.pubTimes = this.pubTimes.slice(0, 1);
				// console.log(this.pubTimes);

				let currentDay = new Date(timeStamp).getDate() + '';
				let currentMonth = new Date(timeStamp).getMonth() + 1 + '';
				let prevMonth = new Date(new Date(timeStamp).setMonth(new Date(timeStamp).getMonth() - 1)).getMonth() + 1 + '';
				let prevDay = new Date(new Date(timeStamp).setDate(new Date(timeStamp).getDate() - 1)).getDate() + '';
				let prevPrevDay = new Date(new Date(timeStamp).setDate(new Date(timeStamp).getDate() - 2)).getDate() + '';
				// console.log(currentDay, prevDay, prevPrevDay);
				// console.log(currentMonth, prevMonth);

				let monthsArray = [
					'янв.',
					'февр.',
					'мар.',
					'апр.',
					'мая',
					'июн.',
					'июл.',
					'авг.',
					'сент.',
					'окт.',
					'нояб.',
					'дек.',
				];


				for (let i in views48) {
					// console.log(i, views48[i], times48[i]);
					// if (!dayStarted && times48[i].includes('00:00–')) dayStarted = true;


					let x = (Number(i) + 0.5) / (views48.length) * graphLength * 0.92;
					let y = 0.05 + 0.70 * (graphHeight - (views48[i] - minBar) / (maxBar - minBar)) * graphHeight;
					// console.log({x, y});

					let bar = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
					bar.setAttribute('x', x);
					bar.setAttribute('y', y);
					bar.setAttribute('width', 0.02);
					bar.setAttribute('height', graphHeight - y - 0.175);
					bar.setAttribute('rx', '0.01');
					bar.setAttribute('ry', '0.01');
					// if (dayStarted) {
					bar.setAttribute('fill', "#2489FF");
					numViews48 += views48[i];
					// } else {
					//     bar.setAttribute('fill', "rgba(0,0,0,0)");
					//     bar.setAttribute('stroke', "#2489FF");
					//     bar.setAttribute('stroke-width', "0.002");
					// }
					svg2.append(bar);

					// if (Math.random() > 0.16) {

					let time = times48[i].split(',')[1].split(':')[0].trim();

					let chartBarDay = prevPrevDay;
					let chartBarMonth = prevPrevDay - 0 > currentDay - 0 ? prevMonth : currentMonth;
					if (times48[i].includes('Вчера')) {
						chartBarDay = prevDay;
						chartBarMonth = prevDay - 0 > currentDay - 0 ? prevMonth : currentMonth;
					}
					if (times48[i].includes('Сегодня')) {
						chartBarDay = currentDay;
						chartBarMonth = currentMonth;
					}
					// console.log('chartBarDay ', chartBarDay);
					// console.log('chartBarMonth', chartBarMonth);

					// let showYTIcon = flase;
					// for (let el of this.pubTimes) {
					//     let day = el.split(', ')[1].split(' ')[0];
					//     let month = el.split(', ')[1].split(' ')[1].trim();
					//
					// }


					if (this.pubTimes.reduce((acc, el) => {
						// console.log(el);

						let day = el.split(', ')[1].split(' ')[0];
						let month = el.split(', ')[1].split(' ')[1].trim();
						let t = el.split(', ')[2].trim().split(':')[0];

						// console.log('day, chartBarDay', day, chartBarDay, day == chartBarDay);
						// console.log((monthsArray.findIndex(item => item === month)) + 1 + '', chartBarMonth, (monthsArray.findIndex(item => item === month)) + 1 + '' === chartBarMonth);
						// console.log(t, time, t === time);

						return acc || (
							day == chartBarDay
							&& (monthsArray.findIndex(item => item === month)) + 1 + '' === chartBarMonth
							&& t === time
						)
					}, false)) {
						let yt = document.createElementNS('http://www.w3.org/2000/svg', 'use');
						yt.setAttribute('xlink:href', '#youtubeRed');
						yt.setAttribute('x', (x - 0.02) / 0.045);
						yt.setAttribute('y', (graphHeight - 0.11) / 0.045);
						yt.setAttribute('transform', 'scale(0.045, 0.045)');
						svg2.append(yt);

					}

					let date = document.createElementNS('http://www.w3.org/2000/svg', 'text');
					date.setAttribute('x', x + 0.0075);
					date.setAttribute('y', graphHeight - 0.13);
					date.setAttribute('style', "text-anchor: middle; font-family: Roboto; font-size: 0.028px; fill: #888; font-weight: 400;");
					date.innerHTML = time + ':00';
					// if (i != circles.length - 1) svg.append(date);
					if (i % 2) svg2.append(date);


				}
				document.getElementById('graphSlide2').innerHTML += '<use xlink:href="#youtubeRed" x="1.3" y="0.3" transform="scale(0.0005, 0.0005)" />'

				this.numberOfViewsPer48Hours = numViews48 + '';

				let textL = document.createElementNS('http://www.w3.org/2000/svg', 'text');
				textL.setAttribute('x', 0);
				textL.setAttribute('y', graphHeight - 0.02);
				textL.innerHTML = '–48 ч.';
				textL.setAttribute('style', "font-family: Roboto; font-size: 0.04px; fill: #888; font-weight: 400;");
				svg2.append(textL);

				let textR = document.createElementNS('http://www.w3.org/2000/svg', 'text');
				textR.setAttribute('x', graphLength - 0.12);
				textR.setAttribute('y', graphHeight - 0.02);
				textR.innerHTML = 'Сегодня';
				textR.setAttribute('style', "text-anchor: end; font-family: Roboto; font-size: 0.04px; fill: #888; font-weight: 400;");
				svg2.append(textR);


				let text2AxisYmax = document.createElementNS('http://www.w3.org/2000/svg', 'text');
				text2AxisYmax.setAttribute('x', graphLength - 0.02);
				text2AxisYmax.setAttribute('y', 0.065);
				text2AxisYmax.setAttribute('style', "text-anchor: end; font-family: Roboto; font-size: 0.04px; fill: #888; font-weight: 400;");
				// text2AxisYmax.innerHTML = String(maxBar).split('').reverse().splice(0).reverse().join('') + ' ';
				text2AxisYmax.innerHTML = this.addMK(maxBar, 1);
				svg2.append(text2AxisYmax);

				let text2AxisYmin = document.createElementNS('http://www.w3.org/2000/svg', 'text');
				text2AxisYmin.setAttribute('x', graphLength - 0.02);
				text2AxisYmin.setAttribute('y', graphHeight - 0.24);
				text2AxisYmin.setAttribute('style', "text-anchor: end; font-family: Roboto; font-size: 0.04px; fill: #888; font-weight: 400;");
				// text2AxisYmin.innerHTML = String(minBar).split('').reverse().splice(0).reverse().join('') + ' ';
				text2AxisYmin.innerHTML = this.addMK(minBar, 1);
				svg2.append(text2AxisYmin);

				let text2AxisYmid = document.createElementNS('http://www.w3.org/2000/svg', 'text');
				text2AxisYmid.setAttribute('x', graphLength - 0.02);
				text2AxisYmid.setAttribute('y', graphHeight / 2 - 0.07);
				text2AxisYmid.setAttribute('style', "text-anchor: end; font-family: Roboto; font-size: 0.04px; fill: #888; font-weight: 400;");
				// text2AxisYmid.innerHTML = String((maxBar - minBar) >> 1).split('').reverse().splice(0).reverse().join('') + ' ';
				text2AxisYmid.innerHTML = this.addMK((maxBar - minBar) >> 1, 1);
				svg2.append(text2AxisYmid);


				let yMaxDash = 0.05 + 0.75 * (graphHeight / (maxBar - minBar)) * graphHeight;

				let dashedLineMax = document.createElementNS('http://www.w3.org/2000/svg', 'line');
				dashedLineMax.setAttribute('x1', 0);
				dashedLineMax.setAttribute('x2', graphLength);
				dashedLineMax.setAttribute('y2', yMaxDash);
				dashedLineMax.setAttribute('y1', yMaxDash);
				dashedLineMax.setAttribute('stroke', "rgba(255, 255, 255, 0.2)");
				dashedLineMax.setAttribute('stroke-width', "0.002");
				dashedLineMax.setAttribute('stroke-dasharray', "0.02 0.03");
				svg2.append(dashedLineMax);

				let yMinDash = 0.05 + 0.75 * (graphHeight - minBar / (maxBar - minBar)) * graphHeight;

				let dashedLineMin = document.createElementNS('http://www.w3.org/2000/svg', 'line');
				dashedLineMin.setAttribute('x1', 0);
				dashedLineMin.setAttribute('x2', graphLength);
				dashedLineMin.setAttribute('y2', yMinDash);
				dashedLineMin.setAttribute('y1', yMinDash);
				dashedLineMin.setAttribute('stroke', "rgba(255, 255, 255, 0.2)");
				dashedLineMin.setAttribute('stroke-width', "0.002");
				dashedLineMin.setAttribute('stroke-dasharray', "0.02 0.03");
				svg2.append(dashedLineMin);

				let yMidDash = 0.05 + 0.75 * (graphHeight - 0.5) * graphHeight;

				let dashedLineMid = document.createElementNS('http://www.w3.org/2000/svg', 'line');
				dashedLineMid.setAttribute('x1', 0);
				dashedLineMid.setAttribute('x2', graphLength);
				dashedLineMid.setAttribute('y2', yMidDash);
				dashedLineMid.setAttribute('y1', yMidDash);
				dashedLineMid.setAttribute('stroke', "rgba(255, 255, 255, 0.2)");
				dashedLineMid.setAttribute('stroke-width', "0.002");
				dashedLineMid.setAttribute('stroke-dasharray', "0.02 0.03");
				svg2.append(dashedLineMid);

				if (!this.showSiteOnTV) {
					let sx = document.documentElement.clientWidth / 1280;
					let sy = document.documentElement.clientHeight / 720;
					this.scaleFactor = 1 / Math.min(sx, sy);
					document.querySelector('.wrapper').style.transform = 'scale(' + Math.min(sx, sy) + ')';
					let tx = sy < sx ? (document.documentElement.clientWidth - document.querySelector('.wrapper').getBoundingClientRect().width) / 2 : 0;
					document.querySelector('.wrapper').style.transform = 'scale(' + Math.min(sx, sy) + ') translateX(' + tx + 'px)';
					document.querySelector('.wrapper').style.transformOrigin = 'left top';

				}

				document.querySelector('.slide-1').classList.remove('opacity0');

				document.getElementById('app').style.opacity = 1;

			}, 2200
			);


		},

		changeSlide(move) {
			clearInterval(this.changeSlideInterval);
			this.animState = true;
			// let idx;
			let slides = [...document.querySelectorAll('.slide')];
			// console.log(slides);
			let activeIdx = -1;
			slides.forEach((s, idx) => {
				s.classList.add('opacity0');
				s.classList.contains('active') ? activeIdx = idx : '';
				setTimeout(() => {
					s.classList.remove('active');
				}, 2000);
			});
			// console.log(activeIdx);
			// console.log(typeof activeIdx);
			move == 'next' ? activeIdx++ : activeIdx += slides.length - 1;
			// console.log(activeIdx);
			activeIdx = activeIdx % slides.length;
			// console.log(activeIdx);
			setTimeout(() => {
				slides.forEach((s, idx) => {
					s.classList.remove('active');
					if (idx == activeIdx) {
						s.classList.add('active')
						setTimeout(() => {
							s.classList.remove('opacity0');
							this.animState = false;
						}, 200);
					}
				});
				// console.log(slides);

			}, 2000);

			this.changeSlideInterval = setInterval(() => {
				this.changeSlide('next');
			}, this.periodOfChangeSlides * 1000);
			/*
							// console.log(slides);
							for (let i in slides) {
								 let slide = slides[i];
								 // slide.classList.remove('opacity1');
								 slide.classList.add('opacity0');
								 if (slide.classList.contains('active')) {
									  idx = Number(i);
									  console.log({idx});
									  setTimeout(() => {
											slide.classList.remove('active');
											move === 'next' ? idx++ : idx--;
											idx = idx % slides.length;
											// if (idx === slides.length) idx = 0;
											// if (idx === -1) idx = slides.length - 1;
											// slides[idx].classList.add('opacity0');
											slides[idx].classList.add('active');
											setTimeout(() => {
												 slides[idx].classList.remove('opacity0');

											}, 100)
											// slides[idx].classList.add('opacity1');
											// slides[idx].classList.remove('opacity0');


											this.changeSlideInterval = setInterval(() => {
												 this.changeSlide('next');
											}, this.periodOfChangeSlides * 1000)

									  }, 2000);
								 }
							}
							*/

			// setTimeout(() => {
			//     move === 'next' ? idx++ : idx--;
			//     if (idx === slides.length) idx = 0;
			//     if (idx === -1) idx = slides.length - 1;
			//     // slides[idx].classList.add('opacity0');
			//     slides[idx].classList.add('active');
			//     slides[idx].classList.add('opacity1');
			//     slides[idx].classList.remove('opacity0');
			//     setTimeout(() => {
			//         // slides[idx].classList.remove('opacity1');
			//     }, 2000);
			// }, 2100);
			// console.log(move);
		}
	}
}
</script>

<style lang="scss" scoped>
@import "Monitor";
</style>

<style lang="scss">
@import "MonitorNotScoped";

html.white {
	filter: invert(1) hue-rotate(180deg);

	img {
		filter: invert(1) hue-rotate(180deg);

	}
}
</style>
